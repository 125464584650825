const getters = {
    productivitySelection: state => state.misc.productivitySelection,
    features: state => state.features.features,
    lastVersion: state => state.features.lastVersion,
    token: state => state.user.token,
    expiry: state => state.user.expiry,
    avatar: state => state.user.avatar,
    name: state => state.user.name,
    id: state => state.user.ID,
    roles: state => state.user.roles,
    activeWorkListView: state => state.workList.activeWorkListView,
    activeProvider: state => state.workList.activeProvider,
    allProviders: state => state.workList.allProviders,
    activeSideBarCollapse: state => state.workList.activeSideBarCollapse,
    activeQueue: state => state.workList.activeQueue,
    filters: state => state.workList.filters,
    todoList: state => state.workList.todoList,
}
export default getters
