import Layout from "@/layout";

const componentsRoutes = [
    {
        path: '/profile',
        component: Layout,
        hidden: true,
        children: [
            {
                path: 'edit',
                component: () => import('@/views/user/profile/index'),
                name: 'Profile',
                meta: {
                    title: 'Profile',
                    icon: 'user',
                }
            }
        ],
    },
    {
        path: '/account',
        component: Layout,
        hidden: false,
        children: [
            {
                path: 'list',
                component: () => import('@/views/providers/list/index'),
                name: 'accounts',
                meta: {
                    title: 'Work Queues',
                    icon: 'resolutions',
                    activeIcon: 'resolutions-active',
                    protected: true,
                    roles: ['Administrator', 'Senior Analyst', 'Junior Analyst', 'View Only'],
                    showProvider: true
                }
            },
            {
                path: ':id/resolution/:itemId',
                props: true,
                component: () => import('@/views/providers/resolution/index'),
                name: 'resolution',
                hidden: true,
                meta: {
                    title: 'Work Queues > Resolution',
                    icon: 'user',
                    protected: true,
                    roles: ['Administrator', 'Senior Analyst', 'Junior Analyst', 'View Only'],
                    showProvider: true,
                    providerId: 'id'
                }
            },
            {
                path: ':providerId/adhoc/:patientAccountNumber',
                props: true,
                component: () => import('@/views/providers/resolution/AdhocDataView'),
                name: 'adhoc',
                hidden: true,
                meta: {
                    title: 'Resolution > Adhoc Resolution',
                    icon: 'user',
                    protected: true,
                    roles: ['Administrator', 'Senior Analyst', 'Junior Analyst', 'View Only'],
                    showProvider: true,
                    providerId: 'providerId'
                }
            },
        ],
    },
    {
        path: '/reports',
        component: Layout,
        hidden: false,
        children: [
            {
                path: '',
                component: () => import('@/views/dashboard/index'),
                name: 'reports',
                meta: {
                    title: 'Reports',
                    icon: 'reports',
                    activeIcon: 'reports-active',
                    protected: true,
                    roles: ['Administrator', 'Senior Analyst', 'Junior Analyst', 'View Only', 'Provider']
                }
            }
        ],
    },
    {
        path: '/tasks',
        component: Layout,
        hidden: false,
        children: [
            {
                path: '',
                component: () => import('@/views/todo-list/index'),
                name: 'tasks',
                meta: {
                    title: 'Productivity report',
                    icon: 'todo',
                    activeIcon: 'todo-active',
                    protected: true,
                    roles: ['Administrator', 'Senior Analyst', 'Junior Analyst']
                }
            }
        ],
    },
    {
        path: '/search',
        component: Layout,
        hidden: false,
        children: [
            {
                path: ':query',
                component: () => import('@/views/global_search/search'),
                name: 'search',
                props: true,
                meta: {
                    title: 'Search',
                    icon: 'search',
                    activeIcon: 'search-active',
                    protected: true,
                    roles: ['Administrator', 'Senior Analyst', 'Junior Analyst', 'View Only', 'Provider']
                }
            }
        ],
    },
    // {
    //     path: '/provider-status',
    //     component: Layout,
    //     hidden: false,
    //     children: [
    //         {
    //             path: '',
    //             component: () => import('@/views/provider_status/index'),
    //             name: 'Providers',
    //             meta: {
    //                 title: 'Provider status',
    //                 icon: 'status',
    //                 activeIcon: 'status-active',
    //                 protected: true,
    //                 roles: ['Administrator', 'Senior Analyst', 'Junior Analyst']
    //             }
    //         }
    //     ],
    // },
    {
        path: '/settings',
        component: Layout,
        hidden: false,
        children: [
            {
                path: '',
                component: () => import('@/views/settings/index'),
                name: 'settings',
                meta: {
                    title: 'Settings',
                    icon: 'settings',
                    activeIcon: 'settings-active',
                    protected: true,
                    roles: ['Administrator']
                }
            }
        ],
    }
]

export default componentsRoutes
