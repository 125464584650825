import request from '@/utils/request'

export function loadAccounts() {
    return request({
        url: 'v1/customer_accounts/',
        method: 'get',
        cache: true
    }).then(r => r.data)
}

export function loadAccountMeta(id, list) {
    const worklist = list === 'work' ? '?list=user' : '?list=all'
    const url = `v1/customer_accounts/${id}/${worklist}`
    return request({
        url: url,
        method: 'get',
    })
}

export function loadAccountFilterCount(id, list) {
    const worklist = list === 'work' ? '?list=user' : '?list=all'
    const url = `v1/customer_accounts/${id}/count/${worklist}`
    return request({
        url: url,
        method: 'get',
        cache: true
    })
}

export function loadTableData(provider, options={}) {
    return request({
         url: `v1/customer_accounts/${provider}/`,
         method: 'get',
         params: options,
        cache: false
    }).then(r => r.data)
}

export function loadAll(id, filter, list) {
    const worklist = list === 'work' ? 'worklist/' : ''
    const url = `v1/customer_accounts/${id}/${worklist}${filter}`

    return request({
        url: url,
        method: 'get',
        cache: false
    })
}


export function loadItemDetail(id, itemId) {
    return request({
        url: `v1/customer_accounts/${id}/item/${itemId}/`,
        method: 'get',
    })
}

export function loadAccountSettings(accountId) {
    return request({
        url: `v1/reports/account_id/${accountId}/milestone/`,
        method: 'get'
    }).then(d => d.data)
}


export function updateMilestones(accountId, milestoneSettings) {
    return request({
        url: `v1/reports/account_id/${accountId}/milestone/`,
        method: 'post',
        data: milestoneSettings
    })
}

export function globalSearch({ patientAccountNumber, page = 1, page_size = 10 }) {
    return request({
        url: 'v1/customer_accounts/global_search/',
        method: 'get',
        params: {
            patient_account_number: patientAccountNumber,
            page,
            page_size
        },
        cache: false
    }).then(response => response.data)
}
